@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@layer components {
  .btn-primary {
    @apply px-5 mt-2 py-1.5 text-sm bg-primary text-white font-[500] rounded-lg border-[1px] border-primary;
  }

  .btn-secondary {
    @apply px-5 mt-2 py-1.5 text-sm text-gray-500 font-[500] rounded-lg border-[1px] border-gray-400;
  }

  .title {
    @apply text-2xl font-[600] text-[#101828] ease-in duration-300;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.2s;
}
